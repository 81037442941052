<template>
  <div class="settings">
    <div :class="'mobileNavBar ' + deviceType">
      <div class="appBar-header">
        <div @click="routeBack('/',false)" class="header-route-back">
          <span v-if="deviceType === 'ios'">
            <img class="header-route-back-image-ios" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
          </span>
          <span v-else>
            <img class="header-route-back-image-android" :src="$root.CDN_SITE + 'icon/tansel_mobile_black_back.svg'">
          </span>
        </div>
        <div class="header-title-container">
          <p class="header-title">
            Tekliflerim
          </p>
        </div>
      </div>
    </div>

    <div class="finance-container">
      <loader v-if="!pageLoaded"  style="margin-top: 40vh;"></loader>

      <div v-if="pageLoaded">

        <div class="finance-activities">
          <div v-for="offer in offers" class="finance-activities-item" @click="openModal(offer)">
            <div>
              <div class="finance-activities-item-description">
                <span class="finance-activities-item-description-text">
                  {{ offer.buyername }}
                </span>
                <span v-if="offer.type === 'request'" style="font-size: 12px; margin-top: 10px;">
                  {{offer.request_title}}
                </span>
                <span class="finance-activities-item-description-status" :style="'color:' + (offer.isCancel ? 'rgb(253, 64, 86)' : offer.status_text_color)">
                  {{ offer.isCancel ? 'İptal Edildi' : offer.status_text }}
                </span>
              </div>
              <div class="finance-activities-item-date">
                {{ Number(offer.created_at) | customDateFormat('dd MMM yyyy') }}
              </div>
            </div>
            <div class="finance-activities-item-total">
              {{ offer.total }}
            </div>
          </div>
        </div>
        <div v-if="seemorediv && !showLoader" @click="getAllOffers(limit, offset, true)" class="see-more">
          Daha fazla gör +
        </div>
        <loader v-if="showLoader"></loader>
      </div>
    </div>

  </div>
</template>

<script>

  import WorkstationNav from "../navComponent";

  export default {
    name: "src-pages-body-workstation-sendOffer-v1_mobile",
    components: {
      WorkstationNav
    },
    data() {
      return {
        pageLoaded: null,
        offers: [],
        seemorediv: false,
        totalCount: 0,
        offset: 0,
        limit: 10,
        showLoader: false
      }
    },

    methods: {
      openModal(offer) {
        if (offer.type === "offer") {
          this.getOfferDetail(offer);
        }

        if (offer.type === "request") {
          this.getOfferDetail(offer, offer.type);
        }

      },

      getOfferDetail(offer, type) {
        this.api.seller.getOfferDetail(offer.id, false, type)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.CUSTOM_OFFER_DETAIL, info: {data: result.data.offerDetail}});

            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      getAllOffers(limit, offset, comingFromSeemore) {
        if (comingFromSeemore) {
          this.showLoader = true;
        }
        this.api.seller.getAllOffers(limit, offset)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.showLoader = false;
              this.pageLoaded = true;
              this.offers = this.offers.concat(result.data.offers);
              this.totalCount = result.totalCount;
              this.seemorediv = result.data.seemorediv;
              this.offset += this.limit;
            } else {
              this.$router.push('/');
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      }
    },

    created() {
      this.getAllOffers(this.limit, this.offset);
      this.EventBus.$on('sendOfferCancelRequest', received => {
        if(received.isCancel) {
          let offer = this.offers.find(offer => offer.id === Number(received.offer.id))
          offer.isCancel = true;
          this.offers = [...this.offers]
        }
      });
    }
  }
</script>

<style scoped lang="scss">
  .mobileNavBar{
    background-color: #FFF;
    box-shadow: none;
    border-bottom: solid 1px #dfe4ec;
  }
  .header-title-container{
    padding-right: 18px;
  }

  .finance-container{
    padding-top: 56px;
    padding-bottom: 50px;
    background-color: #f4f5f7;
  }

  .finance-header{
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFF;
  }

  .finance-activities{
    padding: 15px;
  }

  .finance-activities-item{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 17px 15px 12px;
    background-color: #FFF;
    border: solid 1px #dfe4ec;
    margin-bottom: 10px;
  }

  .finance-header-available-cash{
    font-size: 14px;
    font-weight: 600;
    line-height: 1.29;
    color: #4b4f52;
  }

  .withdrawfundsModalTrigger{
    padding-top: 2px;
    font-size: 14px;
    line-height: 1.29;
    color: #2d3640;
  }

  .finance-activities-item-description{
    display: flex;
    flex-direction: column;
  }

  .finance-activities-item-description-text{
    font-size: 16px;
    font-weight: 500;
    line-height: 1.12;
    color: #2d3640;
  }

  .finance-activities-item-description-status{
    padding-top: 1px;
    font-size: 13px;
    font-style: italic;
    line-height: 1.38;
    color: #8b95a1;
    margin-top: 5px;
  }

  .finance-activities-item-date{
    padding-top: 11px;
    font-size: 13px;
    line-height: 1.38;
    text-align: left;
    color: #8b95a1;
  }

  .finance-activities-item-total{
    font-size: 16px;
    line-height: 1.12;
  }

  .see-more{
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #2d3640;
  }

  .super-button-light-small{
    width: 100px;
    height: 40px;
    border-radius: 2px;
    border: solid 1px #2d3640;
    font-size: 14px;
    font-weight: 600;
    color: #2d3640;
    padding:0;
  }

</style>
